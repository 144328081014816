<template>
  <v-row>
    <v-col
      v-for="(value, v) in paginatedStructureValuesItems"
      v-bind:key="v"
      cols="4"
    >
      <SingleStructureComponent :nomenclators="nomenclators" :is-group="isGroup" @validation:update="$emit('validation:update', $event)" @update:group="$set(value, 'group', $event)" @update:structure="$set(value, 'structure', $event)" :config="config" :ref-key="refKey" :value="value" :valid="valid" />
    </v-col>
    <v-col cols="12">
      <FooterPaginationComponent
        :valid="valid"
        :amount="15"
        :page="currentStructureValuesPage"
        :per-page="structureValuesPerPage"
        :values="values"
        :items-per-page="[15, 24, 38]"
        @update:items="updateItems"
      />
    </v-col>
  </v-row>
</template>

<script>
  import _ from "lodash"
  import { mapActions, mapGetters } from "vuex"
  import sortList from "@/services/OrderListService"
  import LanguajeService from "@/services/LanguajeService"
  import ReadMore from "@/components/ReadMoreComponent"
  import FooterPaginationComponent from "@/module/records/components/commons/FooterPaginationComponent"
  import SingleStructureComponent from '@/module/records/components/commons/SingleStructureComponent'

  export default {
    name: "StructureValuesComponent",
    components: { SingleStructureComponent, FooterPaginationComponent, ReadMore },

    props: {
      isGroup: {
        type: Boolean,
        required: true
      },
      nomenclators: {
        type: Array,
        required: true,
        default: () => {
          return []
        },
      },
      group: {
        type: Object,
        required: false,
      },
      refKey: {
        type: Number|String,
        required: false,
      },
      config: {
        type: Object,
        required: true,
      },
      values: {
        type: Array,
        required: true,
        default: () => {
          return []
        },
      },
      valid: {
        type: Boolean,
        required: true,
      },
    },

    data() {
      return {
        currentStructureValuesPage: 1,
        structureValuesPerPage: 15,
        validateOnEnter: false,
        source: null,
        textProps: {
          color: "secondary",
          itemColor: "secondary",
        },
      }
    },

    async mounted() {
      this.$emit("loading:update", true)

      const promises = []

      _.forEach(this.paginatedStructureValuesItems, object => {
        if (this.nomenclators.includes(object.structure.structure_type))
          promises.push(
            this.searchProcessStructure([
              object.recordStructureValue.record_value,
              object.recordStructureValue.processStructureId.id,
              this.source,
            ])
              .then(res => {
                this.$set(object, "structure", {
                  dataToSelect: res.dataToSelect,
                  ...object.structure,
                  ...res,
                })
              })
              .catch(console.log)
          )
      })

      await Promise.all(promises).finally(() => {
        this.$emit("loading:update", false)
      })
    },

    computed: {
      ...mapGetters({
        requireRules: "general/requireRules",
        selectRule: "general/selectRule",
        requireAndLotRules: "general/requireAndLotRules",
        lotRules: "general/lotRules",
      }),
      paginatedStructureValuesItems() {
        const startIndex =
          (this.currentStructureValuesPage - 1) * this.structureValuesPerPage
        const endIndex = startIndex + this.structureValuesPerPage

        return this.values.slice(startIndex, endIndex)
      },
      decimalFormat() {
        return value =>
          !["CI", "CO", "C"].includes(value.structure.structureCode) &&
          (value.structure.afterPoint !== null ||
            value.structure.afterPoint !== "-")
            ? value.structure.afterPoint
            : this.config.company.traceability.structure.number.afterPoint !==
              null
            ? parseInt(
                this.config.company.traceability.structure.number.afterPoint
              )
            : 3
      },
    },

    methods: {
      ...mapActions({
        searchProcessStructure: "proccess/searchProcessStructure",
      }),
      updateItems(newOptions) {
        this.currentStructureValuesPage = newOptions.page
        this.structureValuesPerPage = newOptions.itemsPerPage

        _.forEach(this.paginatedStructureValuesItems, object => {
          if (this.nomenclators.includes(object.structure.structure_type))
            this.searchProcessStructure([
              object.recordStructureValue.record_value,
              object.recordStructureValue.processStructureId.id,
              this.source,
            ])
              .then(res => {
                this.$set(object, "structure", {
                  dataToSelect: res.dataToSelect,
                  ...object.structure,
                  ...res,
                })
              })
              .catch(console.log)
        })
      },
      lotRulesRequired(required, structureType) {
        if (required && structureType === "LOTE") {
          return this.requireAndLotRules
        } else if (required) {
          return this.requireRules
        } else {
          return this.lotRules
        }
      },
      translate(key) {
        return LanguajeService.getKey3(key)
      },
      translateProcessDataAdd(data, structure) {
        if (
          ["SEASON", "SEASONSIMPLE", "LIST", "LISTSIMPLE"].includes(
            structure.structure_type
          )
        ) {
          return sortList.orderNumbersAndLettersList(
            _.map(data, details => {
              return {
                ...details,
                name: LanguajeService.getKey3(details.name),
              }
            })
          )
        } else {
          return sortList.orderListByUppercase(
            _.map(data, details => {
              return {
                ...details,
                name: LanguajeService.getKey3(details.name),
              }
            }),
            "name"
          )
        }
      },
    },

    destroyed() {
      this.$root.$off("clearDatetimeField")
    },
  }
</script>

<style scoped></style>
