<template>
  <span>
    <!--TEXT & LACRE-->
    <v-text-field
      v-if="
        value.structure.structure_type === 'TEXT' ||
        value.structure.structure_type === 'LACRE' ||
        value.structure.structure_type === 'QR'
      "
      v-model="value.recordStructureValue.record_value[0]"
      :class="value.structure.is_required === true ? 'required' : ''"
      :clearable="true"
      :rules="value.structure.is_required === true ? requireRules : []"
      onkeypress="return (event.charCode != 34)"
      @input="$emit('bug_refresh', value.structure.is_required)"
      persistent-hint
      small
      flat
      outlined
      dense
      color="secondary"
    >
      <template v-slot:label>
        {{ translate(value.structure.language_key, true) }}
        <span v-if="value.structure.is_required" class="red--text"
          ><strong>*</strong></span
        >
      </template>
    </v-text-field>

    <!--LOTE-->
    <v-text-field
      v-if="value.structure.structure_type === 'LOTE'"
      v-model="value.recordStructureValue.record_value[0]"
      :class="value.structure.is_required === true ? 'required' : ''"
      :clearable="true"
      persistent-hint
      small
      flat
      outlined
      dense
      color="secondary"
      :rules="
        lotRulesRequired(
          value.structure.is_required === true,
          value.structure.structure_type
        )
      "
      onkeypress="return (event.charCode != 34)"
      @input="$emit('bug_refresh', value.structure.is_required)"
    >
      <template v-slot:label>
        {{ translate(value.structure.language_key, true) }}
        <span v-if="value.structure.is_required" class="red--text"
          ><strong>*</strong></span
        >
      </template>
    </v-text-field>

    <!--NUMBER-->
    <v-currency-field
      v-if="value.structure.structure_type === 'NUMBER'"
      :ref="'currency' + refKey"
      v-model="value.recordStructureValue.record_value[0]"
      :auto-decimal-mode="false"
      :class="value.structure.is_required === true ? 'required' : ''"
      :clearable="true"
      :decimal-length="decimalFormat(value)"
      :rules="value.structure.is_required === true // (value.structure.structureCode === 'CO' && integrations.IG) ? requireAndFlowRules(value.structure.isValidFlow) : requireRules ? []
          ? requireRules : []
      "
      locale="en"
      onkeypress="return (event.charCode != 34)"
      @input="$emit('bug_refresh', value.structure.is_required)"
      persistent-hint
      small
      flat
      outlined
      dense
      color="secondary"
      :default-value="null"
      :value-as-integer="false"
    >
      <template v-slot:label>
        {{ translate(value.structure.language_key, true) }}
        <span v-if="value.structure.is_required" class="red--text"
          ><strong>*</strong></span
        >
      </template>
    </v-currency-field>

    <span v-if="isGroup">
      <!--NOMENCLATORSIMPLE OR NOMENCLATORMULTIPLE-->
      <v-autocomplete
        v-if="
          value.structure.structure_type === 'PRODUCTSIMPLE' ||
          value.structure.structure_type === 'EQUIPMENTSIMPLE' ||
          value.structure.structure_type === 'OPERATORSIMPLE' ||
          value.structure.structure_type === 'INPUTSIMPLE' ||
          value.structure.structure_type === 'SEASONSIMPLE' ||
          value.structure.structure_type === 'MEASUREMENTUNITSIMPLE' ||
          value.structure.structure_type === 'PRODUCT' ||
          value.structure.structure_type === 'EQUIPMENT' ||
          value.structure.structure_type === 'OPERATOR' ||
          value.structure.structure_type === 'INPUT' ||
          value.structure.structure_type === 'SEASON' ||
          value.structure.structure_type === 'MEASUREMENTUNIT'
        "
        v-model="value.recordStructureValue.record_value[0]"
        :clearable="true"
        :items="
          translateProcessDataAdd(value.structure.dataToSelect, value.structure)
        "
        :no-data-text="$t('general.fields.noDataAvailable')"
        :return-object="false"
        :rules="value.structure.is_required === true ? selectRule : []"
        item-color="secondary"
        item-text="name"
        item-value="name"
        append-icon="mdi-magnify"
        small-chips
        persistent-hint
        small
        flat
        outlined
        dense
        color="secondary"
        @input="$emit('bug_refresh', value.structure.is_required)"
        @update:search-input="searchNomenclator($event, value)"
      >
        <!-- Slot para el footer del desplegable -->
        <template v-slot:append-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content
              class="d-flex flex-nowrap flex-row justify-space-between"
            >
              <v-list-item-subtitle class="text-start"
                ><strong>Total:</strong>
                {{ value.structure.totalDataToSelect }}</v-list-item-subtitle
              >
              <v-list-item-subtitle class="text-end"
                ><strong>Encontrados:</strong>
                {{ value.structure.partialDataToSelect }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-slot:label>
          {{ translate(value.structure.language_key, true) }}
          <span v-if="value.structure.is_required" class="red--text"
            ><strong>*</strong></span
          >
        </template>
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index === 0" small>
            <span>{{ item.name | truncate(50) }}</span>
          </v-chip>
        </template>
        <template v-slot:item="{ item, attrs }">
          <div style="max-width: 300px">
            <read-more
              :classes="'mb-0'"
              :font-size="14"
              :max-chars="80"
              :text="item.name"
            />
          </div>
        </template>
      </v-autocomplete>

      <!--LISTSIMPLE-->
      <v-autocomplete
        v-if="
          value.structure.structure_type === 'LISTSIMPLE' ||
          value.structure.structure_type === 'LIST'
        "
        v-model="value.recordStructureValue.record_value[0]"
        :clearable="true"
        :items="
          translateProcessDataAdd(value.structure.data_source, value.structure)
        "
        :return-object="false"
        :rules="value.structure.is_required === true ? selectRule : []"
        item-text="name"
        item-value="name"
        small-chips
        persistent-hint
        small
        flat
        outlined
        dense
        color="secondary"
      >
        <template v-slot:label>
          {{ translate(value.structure.language_key, true) }}
          <span v-if="value.structure.is_required" class="red--text"
            ><strong>*</strong></span
          >
        </template>
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index === 0" small>
            <span>{{ item.name | truncate(50) }}</span>
          </v-chip>
        </template>
        <template v-slot:item="{ item, attrs }">
          <div style="max-width: 360px">
            <read-more
              :classes="'mb-0'"
              :font-size="14"
              :max-chars="80"
              :text="item.name"
            />
          </div>
        </template>
      </v-autocomplete>
    </span>
    <span v-else>
      <!--NOMENCLATORMULTIPLES-->
      <v-autocomplete
        :ref="`${value.structure.structure_type}${refKey}`"
        v-if="
          value.structure.structure_type === 'PRODUCT' ||
          value.structure.structure_type === 'EQUIPMENT' ||
          value.structure.structure_type === 'OPERATOR' ||
          value.structure.structure_type === 'INPUT' ||
          value.structure.structure_type === 'SEASON' ||
          value.structure.structure_type === 'MEASUREMENTUNIT'
        "
        v-model="value.recordStructureValue.record_value"
        :clearable="true"
        :items="
          translateProcessDataAdd(value.structure.dataToSelect, value.structure)
        "
        :no-data-text="$t('general.fields.noDataAvailable')"
        :return-object="false"
        :rules="value.structure.is_required === true ? selectRule : []"
        item-color="secondary"
        item-text="name"
        item-value="name"
        append-icon="mdi-magnify"
        small-chips
        persistent-hint
        hide-details
        small
        flat
        outlined
        dense
        color="secondary"
        multiple
        @input="$emit('bug_refresh', value.structure.is_required)"
        @update:search-input="searchNomenclator($event, value)"
      >
        <!-- Slot para el footer del desplegable -->
        <template v-slot:append-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content
              class="d-flex flex-nowrap flex-row justify-space-between"
            >
              <v-list-item-subtitle class="text-start"
                ><strong>Total:</strong>
                {{ value.structure.totalDataToSelect }}</v-list-item-subtitle
              >
              <v-list-item-subtitle class="text-end"
                ><strong>Encontrados:</strong>
                {{ value.structure.partialDataToSelect }}</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-slot:selection="{ item, index }">
          <v-chip small v-if="index === 0">
            <span>{{ item.name | truncate(15) }}</span>
          </v-chip>
          <span v-if="index === 1" class="grey--text caption"
            >(+{{ value.recordStructureValue.record_value.length - 1 }}
            {{ $t("general.titles.others") }})</span
          >
        </template>
        <template v-slot:item="{ item, attrs }">
          <div style="max-width: 360px">
            <v-checkbox
              :input-value="attrs.inputValue"
              color="secondary"
              class="pt-2 pb-2 mt-0"
              :hide-details="true"
            >
              <template v-slot:label>
                <read-more
                  :classes="'mb-0'"
                  :text="item.name"
                  :max-chars="80"
                  :font-size="14"
                />
              </template>
            </v-checkbox>
          </div>
        </template>
        <template v-slot:label>
          {{ translate(value.structure.language_key, true) }}
          <span v-if="value.structure.is_required" class="red--text"
            ><strong>*</strong></span
          >
        </template>
      </v-autocomplete>

      <!--NOMENCLATORSIMPLE-->
      <v-autocomplete
        v-if="
          value.structure.structure_type === 'PRODUCTSIMPLE' ||
          value.structure.structure_type === 'EQUIPMENTSIMPLE' ||
          value.structure.structure_type === 'OPERATORSIMPLE' ||
          value.structure.structure_type === 'INPUTSIMPLE' ||
          value.structure.structure_type === 'SEASONSIMPLE' ||
          value.structure.structure_type === 'MEASUREMENTUNITSIMPLE'
        "
        v-model="value.recordStructureValue.record_value[0]"
        :clearable="true"
        :items="
          translateProcessDataAdd(value.structure.dataToSelect, value.structure)
        "
        :no-data-text="$t('general.fields.noDataAvailable')"
        :return-object="false"
        :rules="value.structure.is_required === true ? selectRule : []"
        item-color="secondary"
        item-text="name"
        item-value="name"
        small-chips
        persistent-hint
        hide-details
        small
        flat
        outlined
        dense
        color="secondary"
        @input="$emit('bug_refresh', value.structure.is_required)"
        @update:search-input="searchNomenclator($event, value)"
      >
        <template v-slot:label>
          {{ translate(value.structure.language_key, true) }}
          <span v-if="value.structure.is_required" class="red--text"
            ><strong>*</strong></span
          >
        </template>
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index === 0" small>
            <span>{{ item.name | truncate(50) }}</span>
          </v-chip>
        </template>
        <template v-slot:item="{ item, attrs }">
          <div style="max-width: 300px">
            <read-more
              :classes="'mb-0'"
              :font-size="14"
              :max-chars="80"
              :text="item.name"
            />
          </div>
        </template>
      </v-autocomplete>

      <!--LIST-->
      <v-autocomplete
        :ref="`LIST${refKey}`"
        v-if="value.structure.structure_type === 'LIST'"
        :no-data-text="$t('general.fields.noDataAvailable')"
        v-model="value.recordStructureValue.record_value"
        :clearable="true"
        :return-object="false"
        item-text="name"
        item-value="name"
        :items="
          translateProcessDataAdd(value.structure.data_source, value.structure)
        "
        item-color="secondary"
        class="required"
        :rules="value.structure.is_required === true ? selectRule : []"
        multiple
        small-chips
        persistent-hint
        small
        flat
        outlined
        dense
        color="secondary"
      >
        <template v-slot:label>
          {{ translate(value.structure.language_key, true) }}
          <span v-if="value.structure.is_required" class="red--text"
            ><strong>*</strong></span
          >
        </template>
        <template v-slot:selection="{ item, index }">
          <v-chip small v-if="index === 0">
            <span>{{ item.name | truncate(15) }}</span>
          </v-chip>
          <span v-if="index === 1" class="grey--text caption"
            >(+{{ value.recordStructureValue.record_value.length - 1 }}
            {{ $t("general.titles.others") }})</span
          >
        </template>
        <template v-slot:item="{ item, attrs }">
          <div style="max-width: 360px">
            <v-checkbox
              :input-value="attrs.inputValue"
              color="secondary"
              class="pt-2 pb-2 mt-0"
              :hide-details="true"
            >
              <template v-slot:label>
                <ReadMore
                  :classes="'mb-0'"
                  :text="item.name"
                  :max-chars="80"
                  :font-size="14"
                />
              </template>
            </v-checkbox>
          </div>
        </template>
        <template v-slot:prepend-item>
          <v-checkbox
            v-model="checkboxState"
            color="secondary"
            class="pt-2 pb-2 mt-0 pl-4"
            :hide-details="true"
            @change="selectAll(value.structure.structure_type)"
          >
            <template v-slot:label>
              <v-btn small color="secondary" x-small text>
                {{ $t("general.titles.selectAll") }}
              </v-btn>
            </template>
          </v-checkbox>
          <v-divider class="mb-2"></v-divider>
        </template>
      </v-autocomplete>

      <!--LISTSIMPLE-->
      <v-autocomplete
        v-if="value.structure.structure_type === 'LISTSIMPLE'"
        v-model="value.recordStructureValue.record_value[0]"
        :clearable="true"
        :items="
          translateProcessDataAdd(value.structure.data_source, value.structure)
        "
        :return-object="false"
        :rules="value.structure.is_required === true ? selectRule : []"
        item-text="name"
        item-value="name"
        small-chips
        persistent-hint
        small
        flat
        outlined
        dense
        color="secondary"
      >
        <template v-slot:label>
          {{ translate(value.structure.language_key, true) }}
          <span v-if="value.structure.is_required" class="red--text"
            ><strong>*</strong></span
          >
        </template>
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index === 0" small>
            <span>{{ item.name | truncate(50) }}</span>
          </v-chip>
        </template>
        <template v-slot:item="{ item, attrs }">
          <div style="max-width: 360px">
            <read-more
              :classes="'mb-0'"
              :font-size="14"
              :max-chars="80"
              :text="item.name"
            />
          </div>
        </template>
      </v-autocomplete>
    </span>

    <!--DATETIME-->
    <!--<v-datetime-picker
      v-if="value.structure.structure_type === 'DATATIME'"
      :ref="'datatime' + refKey"
      v-model="value.recordStructureValue.record_value[0]"
      :label="translate(value.structure.language_key, true)"
      :locale="$i18n.locale"
      :required="!!value.structure.is_required"
      :show-date="true"
      :show-time="true"
      :text-field-props="textProps"
      color="secondary"
      date-format="dd / MM / yyyy"
      item-color="secondary"
    />-->
    <a-datetime-picker
      v-model="value.recordStructureValue.record_value[0]"
      v-if="value.structure.structure_type === 'DATATIME'"
      :label="translate(value.structure.language_key, true)"
      :required="!!value.structure.is_required"
      :rules="!!value.structure.is_required ? requireRules : []"
      :format="'dd / MM / yyyy HH:mm'"
      :textFieldProps="{
        'append-icon': 'mdi-calendar',
        'color': 'secondary',
        'outlined': true,
        'dense': true,
        'clearable': true,
      }"
          :datePickerProps="{
        'color': 'secondary'
      }"
          :timePickerProps="{
        'color': 'secondary'
      }"
    />

    <!--DATE-->
    <a-date-picker  v-if="value.structure.structure_type === 'DATA'"
                    v-model="value.recordStructureValue.record_value[0]"
                    :textFieldProps="{ outlined: true, label: translate(value.structure.language_key, true) }"
                    :label="translate(value.structure.language_key, true)"
                    :required="!!value.structure.is_required"
                    :rules="!!value.structure.is_required ? requireRules : []"
    />
    <!--<v-menu
      v-if="value.structure.structure_type === 'DATA'"
      ref="menuDate"
      v-model="menuDate"
      :close-on-content-click="false"
      :return-value.sync="computedDate"
      transition="scale-transition"
      offset-y
      color="secondary"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="computedDate"
          :label="translate(value.structure.language_key, true)"
          append-icon="mdi-calendar"
          readonly
          dense
          outlined
          color="secondary"
          v-bind="attrs"
          v-on="on"
          clearable
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="computedDate"
        no-title
        scrollable
        color="secondary"
      >
        <v-spacer></v-spacer>
        <v-btn
          text
          color="grey"
          @click="menuDate = false"
        >
          {{ $t('general.buttons.clean')}}
        </v-btn>
        <v-btn
          text
          color="secondary"
          @click="$refs.menuDate.save(computedDate)"
        >
          {{ $t('general.buttons.confirm')}}
        </v-btn>
      </v-date-picker>
    </v-menu>-->
    <!--<v-datetime-picker
      v-if="value.structure.structure_type === 'DATA'"
      :ref="'data' + refKey"
      v-model="value.recordStructureValue.record_value[0]"
      :label="translate(value.structure.language_key, true)"
      :locale="$i18n.locale"
      :required="!!value.structure.is_required"
      :show-date="true"
      :show-time="false"
      :text-field-props="textProps"
      color="secondary"
      date-format="dd / MM / yyyy"
      item-color="secondary"
    />-->

    <!--TIME-->
    <a-time-picker v-if="value.structure.structure_type === 'TIME'"
                   v-model="value.recordStructureValue.record_value[0]"
                   :textFieldProps="{ outlined: true, label: translate(value.structure.language_key, true) }"
                   :label="translate(value.structure.language_key, true)"
                   :required="!!value.structure.is_required"
                   :rules="!!value.structure.is_required ? requireRules : []"
    />
    <!-- <v-menu
      v-if="value.structure.structure_type === 'TIME'"
      ref="menuTime"
      v-model="menuTime"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="computedTime"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="computedTime"
            :label="translate(value.structure.language_key, true)"
            append-icon="mdi-clock-time-four-outline"
            readonly
            dense
            outlined
            color="secondary"
            v-bind="attrs"
            v-on="on"
            clearable
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="menuTime"
          v-model="computedTime"
          full-width
          color="secondary"
          @click:minute="$refs.menuTime.save(computedTime)"
        ></v-time-picker>
      </v-menu>-->
    <!--<v-datetime-picker
      v-if="value.structure.structure_type === 'TIME'"
      :ref="'time' + refKey"
      v-model="value.recordStructureValue.record_value[0]"
      :dialog-width="290"
      :label="translate(value.structure.language_key, true)"
      :locale="$i18n.locale"
      :required="!!value.structure.is_required"
      :show-date="false"
      :show-time="true"
      :text-field-props="textProps"
      color="secondary"
      item-color="secondary"
      time-format="HH:mm"
    />-->
  </span>
</template>

<script>
  import _ from "lodash"
  import { mapActions, mapGetters } from "vuex"
  import moment from 'moment';
  import axios from "axios"
  import sortList from "@/services/OrderListService"
  import LanguajeService from "@/services/LanguajeService"
  import ReadMore from "@/components/ReadMoreComponent"
  import NomenclatorComponent from "@/module/records/components/NomenclatorComponent"
  import ADatetimePicker from "@/components/common/ADatetimePicker.vue";
  import ATimePicker from "@/components/common/ATimePicker.vue";
  import ADatePicker from "@/components/common/ADatePicker.vue";

  export default {
    name: "SingleStructureComponent",
    components: {ADatePicker, ATimePicker, ADatetimePicker, NomenclatorComponent, ReadMore },

    props: {
      nomenclators: {
        type: Array,
        required: false,
        default: () => {
          return []
        },
      },
      isGroup: {
        type: Boolean,
        required: true,
      },
      refKey: {
        type: Number|String,
        required: false,
      },
      value: {
        type: Object,
        required: true,
        default: () => {
          return {}
        },
      },
      valid: {
        type: Boolean,
        required: true,
      },
      config: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        source: null,
        menuDate: false,
        menuTime: false,
        menuDatetime: false,
        textProps: {
          persistentHint: true,
          small: true,
          flat: true,
          outlined: true,
          dense: true,
          clearable: true,
          color: "secondary",
          itemColor: "secondary",
        },
        noDataText: "",
        searchInput: "",
        checkboxState: false,
        profile: null,
        integrations: {
          IG: null,
        },
      }
    },

    created() {
      this.profile = localStorage.getItem('profile') ? JSON.parse(localStorage.getItem("profile")) : []
      // this.integrations.IG = _.find(this.profile.integrationsCompany, {
      //   integrationCompanyType: 'IG',
      // })
      // console.log(this.integrations.IG)
    },

    async mounted() {
      this.clearDatetimeField()

      this.$root.$on("clearDatetimeField", () => {
        this.clearDatetimeField()
      })

      this.$root.$on("searchNomenclator", params => {
        this.searchNomenclator(...params)
      })

      if (this.nomenclators.includes(this.value.structure.structure_type))
        await this.searchProcessStructure([
          this.value.recordStructureValue.record_value,
          this.value.recordStructureValue.processStructureId.id,
          this.source,
        ])
          .then(async res => {
            await this.$emit("update:structure", {
              dataToSelect: res.dataToSelect,
              ...this.value.structure,
              ...res,
            })
          })
          .catch(console.log)
    },

    computed: {
      ...mapGetters({
        requireRules: "general/requireRules",
        requireAndFlowRules: "general/requireAndFlowRules",
        selectRule: "general/selectRule",
        requireAndLotRules: "general/requireAndLotRules",
        lotRules: "general/lotRules",
      }),
      decimalFormat() {
        return value =>
          !["CI", "CO", "C"].includes(value.structure.structureCode) &&
          (value.structure.afterPoint !== null ||
            value.structure.afterPoint !== "-")
            ? value.structure.afterPoint
            : this.config.company.traceability.structure.number.afterPoint !==
              null
            ? parseInt(
                this.config.company.traceability.structure.number.afterPoint
              )
            : 3
      },
      isValid() {
        return this.value && this.value.structure && !this.value.structure.is_required;
      },
      computedDate: {
        get() {
          const isoDate = this.value.recordStructureValue.record_value[0];
          if (isoDate && moment(isoDate, moment.ISO_8601, true).isValid()) {
            return moment(isoDate).format('YYYY-MM-DD');
          }
          return '';
        },
        set(newValue) {
          if (newValue && moment(newValue, 'YYYY-MM-DD', true).isValid()) {
            const formattedDate = moment(newValue, 'YYYY-MM-DD').format('YYYY-MM-DD') + 'T00:00:00.000';
            this.$set(this.value.recordStructureValue, 'record_value', [formattedDate]);
          } else {
            this.$set(this.value.recordStructureValue, 'record_value', [null]);
          }
        }
      },
      computedTime: {
        get() {
          const isoTime = this.value.recordStructureValue.record_value[0];
          if (isoTime && moment(isoTime, moment.ISO_8601, true).isValid()) {
            return moment(isoTime).format('HH:mm');
          }
          return '';
        },
        set(newValue) {
          if (newValue && moment(newValue, 'HH:mm', true).isValid()) {
            let datePart = this.value.recordStructureValue.record_value[0];
            if (datePart && moment(datePart, moment.ISO_8601, true).isValid()) {
              datePart = moment(datePart).format('YYYY-MM-DD');
            } else {
              datePart = moment().format('YYYY-MM-DD');
            }
            const formattedTime = `${datePart}T${newValue}:00.000`;
            this.$set(this.value.recordStructureValue, 'record_value', [formattedTime]);
          } else {
            let datePart = this.value.recordStructureValue.record_value[0];
            if (datePart && moment(datePart, moment.ISO_8601, true).isValid()) {
              datePart = moment(datePart).format('YYYY-MM-DD');
              this.$set(this.value.recordStructureValue, 'record_value', [`${datePart}T00:00:00.000`]);
            } else {
              this.$set(this.value.recordStructureValue, 'record_value', [null]);
            }
          }
        }
      }
    },

    methods: {
      ...mapActions({
        searchProcessStructure: "proccess/searchProcessStructure",
      }),
      selectAll(type){
        if( this.checkboxState === false ){
          this.value.recordStructureValue.record_value = [];
        } else {
          let aux = [];
          if(this.value.recordStructureValue.record_value.length > 0 ) {
            const names = this.getByProperty(this.$refs[`${type}${this.refKey}`].filteredItems, 'name')
            _.forEach(names, (name) => {
              if (!this.value.recordStructureValue.record_value.includes(name))
                this.value.recordStructureValue.record_value.push(name)
            })
            aux = this.value.recordStructureValue.record_value;
          }else{
            aux = this.getByProperty(this.$refs[`${type}${this.refKey}`].filteredItems, 'name');
          }

          this.$set(this.value.recordStructureValue, "record_value", aux);
        }
      },
      getByProperty(array, property) {
        return _.map(array, property)
      },
      clearDatetimeField() {
        switch (this.value.structure.structure_type) {
          case "DATATIME":
            if (this.$refs["datatime" + this.refKey] instanceof Array) {
              _.forEach(this.$refs["datatime" + this.refKey], ref => {
                // ref.clearDateTime()
              })
            } else {
              // this.$refs["datatime" + this.refKey].clearDateTime()
            }
            break
          case "DATA":
            if (this.$refs["data" + this.refKey] instanceof Array) {
              _.forEach(this.$refs["data" + this.refKey], ref => {
                // ref.clearDateclearDate()
              })
            } else {
              // this.$refs["data" + this.refKey].clearDate()
            }
            break
          case "TIME":
            if (this.$refs["time" + this.refKey] instanceof Array) {
              _.forEach(this.$refs["time" + this.refKey], ref => {
                // ref.clearTime()
              })
            } else {
              // this.$refs["time" + this.refKey].clearTime()
            }
            break
        }
      },
      async searchNomenclator(e, object) {
        if (!object) {
          this.noDataText = ""
          return
        }

        if (this.source) this.source.cancel()

        const CancelToken = axios.CancelToken
        this.source = CancelToken.source()

        if (e) {
          await this.searchProcessStructure([
            [e],
            object.recordStructureValue.processStructureId.id,
            this.source,
          ])
            .then(async res => {
              await this.$emit("update:structure", {
                dataToSelect: res.dataToSelect,
                ...object.structure,
                ...res,
              })
            })
            .catch(console.log)

          this.source = null
        }
      },
      lotRulesRequired(required, structureType) {
        if (required && structureType === "LOTE") {
          return this.requireAndLotRules
        } else if (required) {
          return this.requireRules
        } else {
          return this.lotRules
        }
      },
      translate(key) {
        return LanguajeService.getKey3(key)
      },
      translateProcessDataAdd(data, structure) {
        if (
          ["SEASON", "SEASONSIMPLE", "LIST", "LISTSIMPLE"].includes(
            structure.structure_type
          )
        ) {
          return sortList.orderNumbersAndLettersList(
            _.map(data, details => {
              return {
                ...details,
                name: LanguajeService.getKey3(details.name),
              }
            })
          )
        } else {
          return sortList.orderListByUppercase(
            _.map(data, details => {
              return {
                ...details,
                name: LanguajeService.getKey3(details.name),
              }
            }),
            "name"
          )
        }
      },
    },

    destroyed() {
      this.$root.$off("clearDatetimeField")
      this.$root.$off("searchNomenclator")
    },
  }
</script>

<style scoped></style>
